import React from 'react';

export const accordionData = [
  {
    title: <>Czym jest dopłata do odszkodowania?</>,
    content: (
      <>
        <p>
          Alternatywnym rozwiązaniem dla osób, które otrzymały zbyt niskie
          odszkodowanie od towarzystwa ubezpieczeniowego, jest skorzystanie z
          usług firmy odszkodowawczej, która po dokładnej analizie kosztorysu
          proponuje dodatkową kwotę w formie dopłaty. Ta kwota jest następnie
          wypłacana poszkodowanemu po zaakceptowaniu oferty przez klienta i
          podpisaniu umowy cesji wierzytelności, która umożliwia firmie
          odszkodowawczej pobranie kwoty dopłaty bezpośrednio od towarzystwa
          ubezpieczeniowego i przekazanie jej na wskazane przez klienta konto
          bankowe.
        </p>
      </>
    ),
  },
  {
    title: <>Jak wygląda proces otrzymania dopłaty do odszkodwania?</>,
    content: (
      <>
        <p>
          Zapewniamy Ci łatwe i szybkie rozwiązanie Twojego problemu. Wystarczy,
          że wypełnisz prosty formularz online i prześlesz kosztorys naprawy
          pojazdu, który otrzymałeś od Twojego ubezpieczyciela. Następnie, nasi
          doświadczeni rzeczoznawcy samochodowi dokładnie przeanalizują Twoją
          sytuację, zapewniając bezpłatną wycenę. Jeśli zauważysz zaniżenia w
          ofercie odszkodowawczej od Twojego ubezpieczyciela, nasza firma
          odszkodowawcza zaproponuje Ci adekwatną dopłatę do odszkodowania. Po
          zaakceptowaniu oferty, umowa cesji wierzytelności zostanie podpisana i
          środki zostaną przelane na Twoje konto nawet w 24h!.
        </p>
      </>
    ),
  },
  {
    title: <>Jak wygląda proces sprzedaży szkody?</>,
    content: (
      <>
        <p>
         Przesyłamy do Ciebie krótki formularz, na podstawie którego robimy umowę, którą podpiszesz jednym kliklnięciem. W związku z tym wszystkie formalności załatwiamy online i możliwie jak naprostrzym sposobem. W takim wypadku przyjmujemy umowę automatycznie, a przelew robimy w 24h od popisania umowy.
        </p>
      </>
    ),
  },
  {
    title: <>Jakie odszkodowanie przysługuje mi po kolizji drogowej?</>,
    content: (
      <>
        <p>
          Po wypadku/kolizji drogowej, przysługuje Ci odszkodowanie z tytułu
          ubezpieczenia OC sprawcy zdarzenia, jeśli to Ty byłeś poszkodowanym.
          Odszkodowanie powinno pokryć szkody materialne, które poniósł
          poszkodowany oraz koszty leczenia i rehabilitacji. W zależności od
          sytuacji, poszkodowany może ubiegać się również o zadośćuczynienie za
          doznane bóle i cierpienia oraz utracone korzyści materialne (np.
          wynagrodzenie za czas niezdolności do pracy). Wysokość odszkodowania
          zależy od wielu czynników, takich jak rodzaj i stopień obrażeń, koszty
          leczenia, wartość zniszczonego mienia itp.
        </p>
      </>
    ),
  },
  {
    title: <>Jakie koszty pokryje moje ubezpieczenie?</>,
    content: (
      <>
        <p>
          Koszty pokrywane przez ubezpieczenie mogą różnić się w zależności od
          rodzaju polisy, ale zwykle obejmują:
          <p>
            1. Koszty naprawy samochodu - ubezpieczenie autocasco (AC) pokrywa
            koszty naprawy lub wymiany uszkodzonych części samochodu w wyniku
            wypadku, kradzieży lub innych zdarzeń losowych.
          </p>
          <p>
            2. Koszty związane z leczeniem - ubezpieczenie NNW (Następstwa
            Nieszczęśliwych Wypadków) lub ubezpieczenie zdrowotne może pokryć
            koszty związane z leczeniem urazów lub chorób wynikających z
            wypadku.
          </p>
          <p>
            3. Koszty wynajmu samochodu zastępczego - ubezpieczenie autocasco
            lub ubezpieczenie Assistance może pokryć koszty wynajmu samochodu
            zastępczego na czas naprawy lub do czasu otrzymania odszkodowania.
          </p>
          <p>
            4. Koszty transportu i holowania - ubezpieczenie Assistance może
            pokryć koszty transportu i holowania samochodu do najbliższego
            warsztatu lub w innej wskazanej lokalizacji.
          </p>
          <p>
            5. Koszty związane z wypadkiem, który wydarzył się za granicą -
            ubezpieczenie Assistance lub ubezpieczenie podróżne może pokryć
            koszty leczenia, transportu, noclegów lub innych kosztów związanych
            z wypadkiem, który wydarzył się za granicą.
          </p>
          <p>
            {' '}
            Warto zwrócić uwagę na warunki umowy ubezpieczeniowej, aby wiedzieć,
            jakie koszty są pokrywane, a jakie nie. Przykładowo, niektóre
            ubezpieczenia autocasco mogą nie pokrywać kosztów uszkodzenia opon
            lub szyb samochodowych.
          </p>
        </p>
      </>
    ),
  },
  {
    title: <>Czy muszę złożyć oświadczenie o kolizji w policji?</>,
    content: (
      <>
        <p>
          <p>
            W przypadku wypadku/kolizji drogowej, zgodnie z obowiązującymi
            przepisami, jeśli uczestnicy są zgodni co do wersji wydarzeń, nie
            muszą wzywać policji. W sytuacji, gdy w wyniku zdarzenia kolizji z
            udziałem pojazdów mechanicznych, policja sporządza protokół, który
            stanowi ważny dokument w postępowaniu likwidacyjnym szkody.
          </p>
          <p>
            {' '}
            Złożenie oświadczenia o wypadku/kolizji w policji jest zwykle jednym
            z etapów procedury związanej z uzyskaniem odszkodowania. Osoby
            poszkodowane często muszą złożyć dokumenty związane z postępowaniem
            likwidacyjnym szkody w towarzystwie ubezpieczeniowym, a jednym z
            nich jest protokół policji.
          </p>
          <p>
            {' '}
            Dlatego, jeśli uczestniczyłeś w Jeśli nie wiesz jak powinien
            wyglądać protokół wypadku lub kolizji lepiej powiadomić policję. W
            ten sposób zapewnisz sobie solidną podstawę dla postępowania
            likwidacyjnego szkody, a także zwiększysz swoje szanse na uzyskanie
            odpowiedniego odszkodowania.
          </p>
        </p>
      </>
    ),
  },
];
