import React, { useState } from "react";
import Input from "./Input/Input";
import Button from "./Button/Button";
import { FormErrors } from "./Form/FormErrors";
import styled from "styled-components";
import { GETFORM_FORM_ENDPOINT } from "../data/ConstantData";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { MdArrowRightAlt } from "react-icons/md";
import ModalBootstrap from "./ModalBootstrap";
import { PhoneText } from "../data/ModalData";

function InputPhone() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validate, setValidate] = useState({ phoneValid: false });
  const [blured, setBlured] = useState({ phone: false });
  const [show, setShow] = useState(false);
  const [endFlag, setEndFlag] = useState(false);

  const [outline, setOutline] = useState({
    phone: "none",
    formErrors: {
      phone: "",
    },
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const regexPhoneNumber =
    /^(?:(?:\+|00)48)?\s*(?:\d{3})\s*(?:-)?\s*(?:\d{3})\s*(?:-)?\s*(?:\d{3})$/;

  const handleBlur = () => (e) => {
    const name = e.target.name;
    setBlured((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const handlePhoneNumberChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const result = event.target.value.replace(/\D/g, "");

    // Usuń wszystkie myślniki z wprowadzonego numeru telefonu
    const newPhoneNumber = event.target.value
      .replace(/-/g, "")
      .replace(/\D/g, "");
    //Ogranicz długość wprowadzonego numeru telefonu do 9 znaków

    if (newPhoneNumber.length > 9) {
      return;
    }
    if (newPhoneNumber.length === 9) {
      validateField(name, value);
      setEndFlag(true);
    }
    if (newPhoneNumber.length < 9 && endFlag === true) {
      validateField(name, value);
    }
    setPhoneNumber((prevState) => ({
      ...prevState,
      [name]: result,
    }));
    setValidate((prevState) => ({
      ...prevState,
      [name]: result,
    }));

    // Dodaj myślniki w odpowiednich miejscach
    const formattedPhoneNumber = newPhoneNumber.replace(
      /(\d{3})(\d{3})(\d{3})/,
      "$1-$2-$3"
    );
    setPhoneNumber(formattedPhoneNumber);
  };

  // ************* Handle Color Input Validation *******************/
  // ********* GREEN ////////////
  const greenOutline = (name) => {
    const greenCustom = "#00bc00";
    setOutline((prevState) => ({
      ...prevState,
      [name]: greenCustom,
      formErrors: {
        ...prevState.formErrors,
        [name]: "",
      },
    }));
  };
  // ********* RED ////////////
  const redOutline = (name, errorText) => {
    const redCustom = "#f32525";
    setOutline((prevState) => ({
      ...prevState,
      [name]: redCustom,
      formErrors: {
        ...prevState.formErrors,
        [name]: errorText,
      },
    }));
  };

  function validateField(fieldName, value) {
    let outlineErrors = outline.formErrors;
    let phoneValid = validate.phoneValid.phone;
    phoneValid = regexPhoneNumber.test(value);
    console.log("click");
    console.log(phoneValid);
    phoneValid
      ? greenOutline(fieldName)
      : redOutline(fieldName, "Numer telefonu nie jest poprawny");
    if (!value) {
      outlineErrors[fieldName] = value.length === 0 && "";
    }
    setValidate((prevState) => ({
      ...prevState,
      formValid: phoneValid,
    }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append("phone", phoneNumber);

    axios
      .post(GETFORM_FORM_ENDPOINT, formData, {
        headers: { Accept: "application/json" },
      })
      .then(function () {
        setValidate({ phoneValid: false });
        setPhoneNumber({ phone: "" });
        setOutline((prevState) => ({
          ...prevState,
          phone: "none",
        }));
        setPhoneNumber("");
        handleShow();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <form
      acceptCharset="UTF-8"
      method="POST"
      encType="multipart/form-data"
      id="gatsbyForm"
      data-netlify="true"
      name="contact"
      onSubmit={handleSubmit}
    >
      <InputErrorHandlerWrapper>
        <InputIconWrapper>
          <PhoneIconsStyled />
          <StyledInput
            placeholder="Podaj numer telefonu"
            id="phone"
            name="phone"
            onBlur={handleBlur()}
            outline={outline.phone}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
        </InputIconWrapper>
        <StyledButton redColor="true" disabled={!validate.formValid}>
          Zamów rozmowę
          <MdArrowRightAlt style={{ marginLeft: "10px", fontSize: "2.9rem" }} />
        </StyledButton>

        {blured.phone && phoneNumber.length > 0 ? (
          <FormErrors onlyOne formErrors={outline.formErrors.phone} />
        ) : (
          <FormErrors onlyOne formErrors={outline.formErrors.phone} />
        )}
      </InputErrorHandlerWrapper>
      <Span
        data-tip="Uzupełnij poprawnie numer telefonu"
        data-tip-disable={false}
      ></Span>

      {!validate.formValid && <ReactTooltip />}
      <ModalBootstrap
        show={show}
        handleClose={handleClose}
        title={PhoneText.title}
        desc={PhoneText.desc}
      />
    </form>
  );
}

export default InputPhone;

const StyledButton = styled(Button)`
  font-size: 1.2rem;
  color: white;
  min-height: 35px;
  height: 70px;
  grid-area: button;
  margin-top: -3px;
  text-transform: capitalize;
  border-radius: 15px;
  box-shadow: none;
  margin-left: -15px;
  ::placeholder {
  }
  :focus {
    border: none;
    background: linear-gradient(#88e42c, #56ab02);
  }
  @media screen and (max-width: 1300px) {
  }

  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 15px;
    min-width: 200px;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 15px;
  min-height: 60px;
  font-size: 1.5rem;
  padding: 0 60px;
`;

const InputErrorHandlerWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
    "input button"
    "error .";
  grid-template-columns: 1fr 40%;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "input"
      "error"
      "button";
  }
`;

const InputIconWrapper = styled.div`
  grid-area: input;
  display: flex;
  z-index: 1;
  justify-content: flex-start;
  position: relative;
  @media screen and (max-width: 768px) {
    min-width: 230px;
  }
`;

const PhoneIconsStyled = styled(FaPhoneSquareAlt)`
  position: absolute;
  height: 2.5rem;
  width: 2.5rem;
  top: 50%;
  color: #d0d0d0;
  left: 0.8rem;
  transform: translateY(-50%);
`;

const Span = styled.span`
  width: 100%;
`;
