import React from "react";
import styled from "styled-components";
import NavbarSticky from "../components/NavbarSticky";
import FAQLogo from "../assets/images/FAQ-logo.svg";
import { accordionData } from "../Accordion/AccordionData";
import CustomAccordion from "../Accordion/Accordion";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../styles/GlobalStyles.module.scss";
import { privacy } from "../data/ConstantData";
import Footer from "../components/Footer";
import InputPhone from "../components/InputPhone";

function Faq() {
  return (
    <>
      <NavbarSticky isSticky={true} isHide ShowOnScroll />
      <FAQLogoStyle />
      <Title>Najczęściej zadawane pytania</Title>
      <SubTitle>
        Zapoznaj się z odpowiedziami na pytania, które najczęściej zadają osoby,
        które ucierpiały w wypadkach lub kolizjach drogowych.
      </SubTitle>

      <Wrapper>
        {accordionData.map((item, index) => {
          return (
            <CustomAccordion
              key={index}
              title={item.title}
              content={item.content}
            />
          );
        })}
      </Wrapper>
      <div className={styles.container}>
        <div className={styles.rightSide}>
          <h3 className={styles.h3}>Kontant</h3>
          <h4 className={styles.underLine}>Skontaktuj się z nami</h4>
          <Paragraph>
            Zostaw swój numer telefonu, a nasz konsultant skontaktuje się z Tobą
            w ciągu 24 godzin.
          </Paragraph>
          <InputPhone />
          <SmallParagraph>
            Korzystając z usługi "Zamów rozmowę" i podając swój numer telefonu,
            wyrażasz zgodę na przetwarzanie Twoich danych osobowych, w tym
            numeru telefonu, przez firmę Sprzedam Szkodę w celu przedstawienia
            oferty przez telefon. Przetwarzanie Twoich danych będzie odbywało
            się zgodnie z{" "}
            <A href={privacy.privacy_url}>
              {" "}
              Informacją o administratorze danych{" "}
            </A>{" "}
            , do której warto zapoznać się przed dokonaniem wyboru "Zamów
            rozmowę".
          </SmallParagraph>
        </div>

        <ImageWrapper>
          <StaticImage
            src="../assets/images/consultant.png"
            alt="Konsultantka"
            layout="constrained"
            objectFit="cover"
          ></StaticImage>
        </ImageWrapper>
      </div>

      <Footer />
    </>
  );
}

export default Faq;

const ImageWrapper = styled.div`
  display: flex;
  width: 30%;
  bottom: 0;
  position: absolute;
  left: 50px;
  padding-top: 100px;
  margin: 0;
  @media screen and (max-width: 890px) {
    display: none;
  }
`;

const A = styled.a`
  color: #2d78d6;
  font-size: 0.7rem;
  :hover {
    cursor: pointer;
  }
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  color: grey;
  @media screen and (max-width: 468px) {
    text-align: justify;
    padding: 0 10px;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const SmallParagraph = styled(Paragraph)`
  font-size: 0.7rem;
  text-align: justify;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  width: 80%;
  margin: auto;
  padding-top: 50px;
`;

const FAQLogoStyle = styled(FAQLogo)`
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-top: 130px;
  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

const Title = styled.h1`
  border-top: 6px solid #422784;
  font-size: 3.5rem;
  text-align: center;
  padding-top: 40px;
  width: 100%;

  @media screen and (max-width: 768px) {
    font-size: 2.1rem;
    width: 95%;
  }
  @media screen and (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const SubTitle = styled.h2`
  font-size: 1.5rem;
  text-align: center;
  padding-top: 10px;
  color: grey;
  width: 50%;
  margin: auto;
  @media screen and (max-width: 768px) {
    width: 80%;
    font-size: 1.1rem;
  }
`;
