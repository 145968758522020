import React, { useState } from "react";
import PropTypes from "prop-types";
import * as styles from "./Accordion.module.scss";

const CustomAccordion = ({ title, content }) => {
  const [accordionStatus, setAccordionStatus] = useState(false);

  const onClicked = () => {
    setAccordionStatus(!accordionStatus);
  };

  return (
    <div
      className={`${styles.accordion} ${
        accordionStatus ? styles.uncollapsed : styles.collapsed
      }`}
    >
      <button onClick={onClicked}>
        {title}
        <span className={styles.arrow}></span>
      </button>
      <div className={styles.accordionPanel}>{content}</div>
    </div>
  );
};

export default CustomAccordion;

CustomAccordion.propTypes = {
  title: PropTypes.object,
  content: PropTypes.object,
};
